import React from 'react';
import Wrapper from './Components/Wrapper.js';
import './App.css';

function App() {
  return(
    <div className= "App">
      <Wrapper />
    </div>
  );
}

export default App;
